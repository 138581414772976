import {
  Title,
  Text,
  Anchor,
  Button,
  Tooltip,
  Divider,
  Group,
  Card,
  Paper,
  createStyles,
  rem,
  HoverCard,
  useMantineTheme,
  MediaQuery,
  Stack,
} from '@mantine/core';
import confetti from 'canvas-confetti';
import React, {
  MouseEventHandler,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import Typewriter from 'typewriter-effect';
import { motion } from 'framer-motion';
import Link from 'next/link';
import Head from 'next/head';
import { InferGetStaticPropsType } from 'next';
import { Carousel } from '@mantine/carousel';
import { useCounter, useDebouncedValue, useMediaQuery } from '@mantine/hooks';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import dynamic from 'next/dynamic';
import { useExperiment } from 'statsig-react';
import { AdvancedImage, lazyload, accessibility, responsive, placeholder } from '@cloudinary/react';
import { name as transformationName } from '@cloudinary/url-gen/actions/namedTransformation';

import {
  WEBSITE_DOMAIN,
  LOMO_DESCRIPTIONS,
  TIER_IMAGES_CDN,
  TIER_IMAGES_TRANSFORM_CDN,
  EDGE_FUNCTIONS_URL,
} from '../src/Constants';
import { cld, invoke, supabaseAnonClient } from '../src/Utils';
import { Database } from '../types/supabase';
import { experimentGetStaticPaths, experimentGetStaticProps } from '../experiments/data-fetchers';
import { homePageGetStaticProps } from '../pages/[[...slug]]';

const useStyles = createStyles((theme) => ({
  categoryCard: {
    height: rem(300),
    marginBottom: theme.spacing.xl,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],

    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
  categoryCardTitleBg: {
    backgroundColor:
      theme.colorScheme === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(4px)',
  },
  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontSize: 100,
    fontWeight: 900,
    letterSpacing: -2,

    [theme.fn.smallerThan('md')]: {
      fontSize: 50,
    },
  },

  typewriter: {
    fontSize: 20,
    marginLeft: 2,
  },

  typewriterCursor: {
    fontSize: 20,
  },

  createButton: {
    transition: 'all 0.2s ease',
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: '$white',
      opacity: 1,
      borderRadius: '$pill',
      transition: 'all 0.4s ease',
    },
    '&:hover': {
      transform: 'translateY(-5px)',
      '&:after': {
        transform: 'scaleX(1.5) scaleY(1.6)',
        opacity: 0,
      },
    },
    '&:active': {
      transform: 'translateY(-2px)',
    },
  },
}));

interface CardProps {
  thumbnail: string;
  name: string;
  description: string;
}

function CategoryCard({ thumbnail, name, description }: CardProps) {
  const { classes } = useStyles();

  const image = cld
    .image(thumbnail)
    .namedTransformation(transformationName('web-category-thumbnail-1x'));
  const image1x = image.toURL();
  const image2x = cld
    .image(thumbnail)
    .namedTransformation(transformationName('web-category-thumbnail-2x'))
    .toURL();

  return (
    <Paper
      shadow="md"
      p="xl"
      mb="xl"
      radius="md"
      w={300}
      sx={{
        overflow: 'hidden',
        position: 'relative',
      }}
      //   sx={{
      //     backgroundImage: `url(${
      //       thumbnail.includes('gif') ? TIER_IMAGES_CDN : TIER_IMAGES_TRANSFORM_CDN
      //     }/${thumbnail}?width=500&height=500)`,
      //   }}
      className={classes.categoryCard}
    >
      <AdvancedImage
        width="300px"
        height="300px"
        loading="lazy"
        srcset={`${image1x} 1x, ${image2x} 2x`}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          objectFit: 'cover',
        }}
        cldImg={image}
        plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
      />
      {/* <Image 
            src={`${TIER_IMAGES_CDN}/${thumbnail}`}
            alt={`${name} category`}
            width={300}
            height={300}
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                objectFit: 'cover',
            }}
        /> */}
      <div className={classes.categoryCardTitleBg}>
        <Title mx="md" order={3}>
          {name}
        </Title>
      </div>
    </Paper>
  );
}

export default function HomeScreen({
  categories,
  experiments,
}: InferGetStaticPropsType<typeof homePageGetStaticProps>) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const session = useSession();
  const router = useRouter();
  const supabase = useSupabaseClient<Database>();
  const title = 'Lomo List | Best Tier List Maker | Create your own tier list ';
  const url = `${WEBSITE_DOMAIN}/`;
  const description =
    'Trying to create or share a Tier List? Lomo is the best Tier List platform on the internet! Checkout our Tier List creator and find out for yourself.';
  const image = 'https://lomolist.com/img/logo-gradient.svg';
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const categorySlides = React.useMemo(
    () =>
      categories
        .filter(
          (categ: { description: string; name: string; thumbnail: string }) => categ.name !== 'NSFW'
        )
        .map((item: { description: string; name: string; thumbnail: string }) => (
          <Carousel.Slide key={item.name}>
            <Link
              href={`/categories/${encodeURIComponent(item.name)}`}
              passHref
              style={{
                textDecorationLine: 'none',
                justifyContent: 'center',
                flexDirection: 'row',
                display: 'flex',
              }}
              //   onClick={() => {
              //     router.push(`/categories/${encodeURIComponent(item.name)}`);
              //   }}
            >
              <CategoryCard {...item} />
            </Link>
          </Carousel.Slide>
        )),
    [categories]
  );

  const [totalClicks, setTotalClicks] = useState(0);
  const [userClicks, setUserClicks] = useState(0);
  const [unsentClicks, setUnsentClicks] = useState(0);
  const [debouncedClicks] = useDebouncedValue(unsentClicks, 200);
  const checkInterval = useRef<NodeJS.Timer | null>(null);
  const intervalsSent = useRef(0);
  const sending = useRef<boolean>(false);
  const { ai_enabled } = experiments;
  //   const { config: ai_enabled, isLoading } = useExperiment('ai_enabled');
  const loadMyClicks = async () => {
    if (session) {
      const { data } = await supabase
        .from('lomo_clicks_users')
        .select('clicks')
        .eq('user_id', session?.user?.id)
        .single();
      if (data?.clicks) {
        setUserClicks(data.clicks);
      }
    }
  };

  const loadGlobalCount = async () => {
    if (sending.current) {
      return;
    }
    if (intervalsSent.current > 100) {
      if (checkInterval.current) {
        clearInterval(checkInterval.current);
      }
      return;
    }
    intervalsSent.current += 1;
    const { data } = await supabase.from('lomo_clicks_global').select('clicks').single();
    if (data?.clicks) {
      if (sending.current) {
        return;
      }
      setTotalClicks(data.clicks);
    }
  };

  const logClicks = async () => {
    if (session && unsentClicks > 0) {
      sending.current = true;
      const token = session?.access_token;
      const clicksToSend = unsentClicks;
      setUnsentClicks(0);
      setUserClicks(userClicks + clicksToSend);
      setTotalClicks(totalClicks + clicksToSend);
      const { data } = await invoke('increase-click-count', EDGE_FUNCTIONS_URL, token, {
        body: {
          amount: clicksToSend,
        },
      });
      await loadMyClicks();
      sending.current = false;
      loadGlobalCount();
    }
  };

  useEffect(() => {
    logClicks();
  }, [debouncedClicks]);

  useEffect(() => {
    loadMyClicks();
    loadGlobalCount();
  }, [session]);

  return (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Head>
          <meta name="title" content={title} />
          <meta name="description" content={description} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={`${url}`} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta property="og:site_name" content="LoMo" />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`${url}`} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" content={image} />
          <link rel="canonical" href={WEBSITE_DOMAIN} />
          <title>{title}</title>
        </Head>

        <Title
          className={classes.title}
          align="center"
          sx={() => ({
            marginTop: 60,

            '@media (max-width: 48em)': {
              marginTop: theme.spacing.xl,
            },
          })}
        >
          Welcome to{' '}
          <HoverCard
            width={280}
            shadow="md"
            onOpen={() => {
              loadGlobalCount();
              if (checkInterval.current) {
                clearInterval(checkInterval.current);
                checkInterval.current = null;
              }
              intervalsSent.current = 0;
              checkInterval.current = setInterval(loadGlobalCount, 1000);
            }}
            onClose={() => {
              if (checkInterval.current) {
                clearInterval(checkInterval.current);
                checkInterval.current = null;
              }
            }}
          >
            <HoverCard.Target>
              <Text
                inherit
                variant="gradient"
                gradient={{ from: '#0037FC', to: '#F60063', deg: 135 }}
                component="span"
                sx={{
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={() => {
                  if (session) {
                    setUnsentClicks(unsentClicks + 1);
                  }
                  confetti({
                    particleCount: 200,
                    startVelocity: 30,
                    spread: 360,
                    origin: {
                      x: Math.random(),
                      // since they fall down, start a bit higher than random
                      y: Math.random() - 0.2,
                    },
                  });
                }}
              >
                LoMo
              </Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <>
                <Text
                  size="sm"
                  inherit={false}
                  sx={{
                    letterSpacing: 1,
                  }}
                >
                  Global Lomo Clicks: {(totalClicks + unsentClicks).toLocaleString()}
                </Text>

                {session ? (
                  <Text
                    size="sm"
                    inherit={false}
                    sx={{
                      letterSpacing: 1,
                    }}
                  >
                    Your Clicks: {(userClicks + unsentClicks).toLocaleString()}
                  </Text>
                ) : (
                  <Text
                    size="sm"
                    color="red"
                    fw={500}
                    inherit={false}
                    sx={{
                      letterSpacing: 1,
                    }}
                  >
                    Sign in to add to the count!
                  </Text>
                )}
              </>
            </HoverCard.Dropdown>
          </HoverCard>
        </Title>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h4>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Text> The best tier list platform on the internet</Text>
            </MediaQuery>
            <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
              <div
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  height: 50,
                  alignItems: 'center',
                }}
              >
                <Tooltip
                  label="source: lomolist.com"
                  arrowSize={20}
                  withArrow
                  position="bottom"
                  color="dark"
                >
                  <Group sx={{ gap: 0 }}>
                    <Text
                      align="center"
                      size="xl"
                      sx={{
                        marginRight: 2,
                      }}
                    >
                      The
                    </Text>
                    <Text
                      component="span"
                      variant="gradient"
                      gradient={{ from: 'violet', to: 'red' }}
                      inherit
                    >
                      <Typewriter
                        options={{
                          strings: LOMO_DESCRIPTIONS,
                          autoStart: true,
                          loop: true,
                          wrapperClassName: classes.typewriter,
                          cursorClassName: classes.typewriterCursor,
                        }}
                      />
                    </Text>

                    <Text align="center" size="xl" mx="auto">
                      <Text component="span" inherit>
                        tier list{' '}
                      </Text>
                      platform on the internet.
                    </Text>
                  </Group>
                </Tooltip>
              </div>
            </MediaQuery>
          </h4>
          <Stack align="center">
            {/* <MediaQuery smallerThan="sm" styles={{ display: 'none' }}> */}
            <Link
              href="create"
              passHref
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                className={classes.createButton}
                mt="xl"
                size="xl"
                variant="gradient"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  confetti({
                    particleCount: 100,
                    startVelocity: 30,
                    spread: 360,
                    origin: {
                      x: event.pageX / window.innerWidth,
                      // since they fall down, start a bit higher than random
                      y: event.pageY / window.innerHeight,
                    },
                  });
                }}
              >
                Create a Tier List Template
              </Button>
            </Link>
            <Link
              href="/drake-kendrick"
              passHref
              style={{
                textDecoration: 'none',
              }}
            >
              <Button
                className={classes.createButton}
                my="xs"
                size="xl"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  confetti({
                    particleCount: 100,
                    startVelocity: 30,
                    spread: 360,
                    origin: {
                      x: event.pageX / window.innerWidth,
                      // since they fall down, start a bit higher than random
                      y: event.pageY / window.innerHeight,
                    },
                  });
                }}
                ta="center"
              >
                <Stack sx={{ gap: 0, width: '100%', alignItems: 'center' }} align="center">
                  <Text>Drake vs Kendrick Diss Tracks</Text>
                </Stack>
              </Button>
            </Link>
            {/* </MediaQuery> */}
            {ai_enabled && (
              <Link
                href="/create-ai"
                passHref
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  className={classes.createButton}
                  my="xs"
                  size="xl"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    confetti({
                      particleCount: 100,
                      startVelocity: 30,
                      spread: 360,
                      origin: {
                        x: event.pageX / window.innerWidth,
                        // since they fall down, start a bit higher than random
                        y: event.pageY / window.innerHeight,
                      },
                    });
                  }}
                  ta="center"
                >
                  <Stack sx={{ gap: 0, width: '100%', alignItems: 'center' }} align="center">
                    <Text>Generate Templates with AI</Text>
                  </Stack>
                </Button>
              </Link>
            )}
            <Divider sx={{ width: '80%' }} mb="xl" />
          </Stack>
          {categorySlides.length > 0 && (
            <>
              <Title
                variant="gradient"
                gradient={{ from: 'violet', to: 'pink' }}
                order={2}
                my="xl"
                ta="center"
              >
                Explore our Tier List Categories
              </Title>
              <Carousel
                slideSize={300}
                breakpoints={[{ maxWidth: 'xs', slideSize: '100%', slideGap: 0 }]}
                slideGap="xl"
                align="center"
                sx={{
                  width: '100%',
                }}
                loop
                dragFree
                controlSize={50}
              >
                {categorySlides}
              </Carousel>
            </>
          )}
        </div>
      </motion.div>
    </>
  );
}
